import React from 'react'

const Services = () => {
  const goldborder = {
    border: '1px solid #ca8d2f',
  }
  return (
    <div>
      <div className='jumbotron jumbotron-fluid mb-0 main bgc-dark'>
      <div className="container-fluid">
          <div className="row">
              <div className="col-md-6">
                <h5 id="main-text"><span id="bold">O</span>riginal</h5>
              </div>
              <div className="col-md-6">
                    <h5 id="secondary-text"><span id="bold">C</span>onsciousness</h5>
              </div>
        </div>
     </div>
      </div>
      <div className='jumbotron jumbotron-services jumbotron-fluid mb-0 bgc-dark'>
        <div className='container-fluid text-center text-white'>
          <h3>SERVICES</h3>
          <div className='row'>
            <div className='col-sm-12 text-center'>
              <img
                src={require('../../img/services/Elem.webp')}
                width='30px'
                alt='Element'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid bgc-dark'>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center'>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/LightLanguage.webp')}
                    alt='Light Language Reiki Sessions'
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>
                    Light Language Reiki<br></br> Sessions
                  </h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/reiki' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center'>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/DNA.webp')}
                    alt='DNA Shamanic Journey'
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>
                    DNA Shamanic <br></br>Journey
                  </h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/dna' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center '>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/Galactic.webp')}
                    alt='Light Language Galactic Activation Attunement'
                  />
                </div>
                <div className='card-body mb-5'>
                  <h5 className='card-title'>
                    Light Language <br></br>Galactic Activation <br></br> Attunement
                  </h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/activation' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center'>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/Coaching.webp')}
                    alt='Coaching Services'
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title pb-3'>
                    Coaching<br></br>Services
                  </h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/coaching' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center'>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/Sound.webp')}
                    alt='Sound Healing'
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>Sound <br></br>Healing</h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/sound' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center'>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/Family.webp')}
                    alt='Family Constellation'
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>Family <br></br> Constellation</h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/family-constellations' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center'>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/Fibo.webp')}
                    alt='Individual Light Language Seal'
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>
                    Individual Light <br></br>Language Seal
                  </h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/seals' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-7 offset-md-3'>
            <div className='card bgc-dark' style={goldborder}>
              <div className='card-horizontal text-center'>
                <div className='img-square-wrapper'>
                  <img
                    className='img-fluid'
                    src={require('../../img/services/Past.webp')}
                    alt='Past Life Regression'
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>
                    Past Life <br></br> Regression
                  </h5>
                  <div className="btn-toolbar text-center pt-3">
                    <div className="col-lg-6">
                        <a href='/services/past-life-regression' className='btn btn-readmore'>Read more</a>
                      </div>
                      <div className="col-lg-6">
                        <a href='/connect' className='btn btn-warning'>Book it</a>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
