import React from 'react'
import {Link} from 'react-scroll'

const DNAShamanic = () => {
    return (
        <section>
        <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 service"></div>
                        <h1 className="text-center centered ">READ  MORE  ABOUT <br></br> OUR SERVICES</h1>
                        <section id="section07" className="demo text-center centered mt-5 smooth-scroll">
                            <Link to="content" smooth="true"><span></span><span></span></Link>
                        </section>
                    </div>
                </div>
            <div id="content"></div>
        </div>
        
        <div className="jumbotron jumbotron-fluid mb-0 bgc-dark services-text">
            <div className="container">
                <h1 className="text-center mb-3 pt-5">DNA SHAMANIC JOURNEY</h1>
                <h6 className="text-center mt-5 mb-5 service-price">
                    <span>1 hr | $88 fee</span></h6>
            </div>
        </div>
        <div className="services-image bgc-dark">
            <div className="container">
                <div className="row text-center">
                <div className="col-3"></div>
                    <div className="col-6">
                        <img src={require('../../img/services/DNAborder.webp')} className="img-fluid" alt='Dna Shamanic Journey'/>                            
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
        <div className="services-text bgc-dark pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <li>Shamanic Journey is an ancestral Method, a tool for healing and spiritual growth, where you travel in the Spirit World of your Ancestors to ask questions and to receive answers. The sesion takes place inside a copper pyramid and  Smudging, Drumming and Light Language Chanting are part of the experience. You will journey within:</li>
                        <li>the lower world( past: facing your unhealed wounds, soul contracts from previous lives, soul parts not expressed or manifested, abilities and skills from you Spirit Animals).</li>
                        <li>the middle world (present: you current Earth experience as a human)</li>
                        <li>the upper world(future: channel your Higher Self to discover how to be in service to self, community and humanity at this level of consciousness)</li>
                        <h3 className="pt-4 pb-5 text-center">DESPACHO CEREMONY</h3>
                        <li>The Shamanic Journey can also include a Despacho: a ceremonial offering of gratitude to Mother Earth (Pachamama) and to the Source. It honors your past, your present, your future together with your Ancestors. It is made of leaves, flowers, seeds, candles, earth and shells. You water it with holy water and then you bury it within the Earth. Extra cost) </li>
                        <div className="text-center pb-4">
                            <a href="/connect" className="btn btn-warning2">Book it</a>
                        </div>
                        <hr></hr>
                     </div>
                     <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        <div className="booking-policy bgc-dark text-center pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <p className="pb-4">Booking policy</p>
                        <p>Bookings close 2 days before the session starts.</p>
                        <p>Cancellation Policy: To cancel or reschedule, please contact us 48 hours prior to your appointment. Any cancelation or "no show up" less than 48 hours in advance cannot be accepted and service invoice will be due.</p>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        </section>
        

    )
}
export default DNAShamanic;