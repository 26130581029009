import React from 'react'
import {NavLink} from 'react-router-dom';

const Navbar = () => {

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bgc-dark sticky-top">
        <a className="navbar-brand pl-5" href="/">
          <img src={require('../../img/Brand.png')} className="d-inline-block align-top brand" alt="OC"></img>
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto navbar-center pl-5">
            {/* <li className="nav-item active pr-3">
              <NavLink to='/services' className="nav-link" activeClassName="main-nav-active">Services</NavLink>
            </li> */}
            <li className="nav-item dropdown pr-3">
                <NavLink className="nav-link dropdown-toggle" to="/services" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" activeClassName="main-nav-active">
                  Services
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <NavLink className="dropdown-item" to="/services">Personal</NavLink>
                    <NavLink className="dropdown-item" to="/comingsoon">Educational</NavLink>
                    <NavLink className="dropdown-item" to="/comingsoon">Corporate</NavLink>
                </div>
            </li>
            <li className="nav-item pr-5">
              <NavLink to='/events' className="nav-link single" activeClassName="main-nav-active">Events</NavLink>
            </li>
            <li className="nav-item pr-5">
                <NavLink to='/retreats' className="nav-link single" activeClassName="main-nav-active">Retreats</NavLink>
            </li>
            <li className="nav-item pr-5">
                <NavLink to='/shop' className="nav-link single" activeClassName="main-nav-active">Shop</NavLink>
            </li>
            <li className="nav-item pr-5">
                <NavLink to='/community' className="nav-link" activeClassName="main-nav-active">Community</NavLink>
            </li>
            <li className="nav-item pr-5">
                <NavLink to='/connect' className="nav-link" activeClassName="main-nav-active">Connect</NavLink>
            </li>
            <li className="nav-item pr-5">
                <NavLink to='/about' className="nav-link" activeClassName="main-nav-active">Anca Botez</NavLink>
            </li>
            </ul>
        </div>
      </nav>
    )
}

export default Navbar;
