import React from 'react'
import {Link} from 'react-scroll'


const Coaching = () => {
    return(
        <section>
        <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 service"></div>
                        <h1 className="text-center centered ">READ  MORE  ABOUT <br></br> OUR SERVICES</h1>
                        <section id="section07" className="demo text-center centered mt-5 smooth-scroll">
                            <Link to="content" smooth="true" duration="2000"><span></span><span></span></Link>
                        </section>
                    </div>
                </div>
            <div id="content"></div>
        </div>
        
        <div className="jumbotron jumbotron-fluid mb-0 bgc-dark services-text">
            <div className="container">
                <h1 className="text-center mb-3 pt-5">COACHING SERVICE</h1>
                <h6 className="text-center mb-5 mt-5 service-price">
                    <span>1 hr | $88 fee</span></h6>
            </div>
        </div>
        <div className="services-image bgc-dark">
            <div className="container">
                <div className="row text-center">
                <div className="col-3"></div>
                    <div className="col-6">
                        <img src={require('../../img/services/Coaching.webp')} className="img-fluid img-border2" alt='Dna Shamanic Journey'/>                            
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
        <div className="services-text bgc-dark pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <li>Do you ask yourself often: “DID I MAKE THE RIGHT CHOICE?</li>
                        <li>Maybe this choice involves your job, the relationship with your parents, your partner or your child, your health condition or your goals and dreams.</li>
                        <li>You make the right choice when what you think (Mind Intelligence) is aligned with how you feel (Heart Intelligence) and you act according to it (Gut Intelligence). This is what we call coherence  and this session is about you learning how to align your thoughts, your feelings and your actions to live a peaceful and fulfilling life.</li>
                        <li>When you manifest coherence you feel peaceful, you feel connected and emotionally secure, you trust, you are motivated and driven and through integration and creativity you actively work on your goals. These are Life’s gifts.</li>
                        <li>Coherence teaches you how to ”find the root” and heal the other spectrum of emotions: your anger, sadness, fear, anxiety & depression, jealousy & envy, loneliness, distrust, self-damage. These are Life’s tests.</li>
                        <li>This session provides NLP techniques, tools and homework for you to explore your Human Potential and to live a fulfilled life. </li>
                        <h3 className="p-5 text-center">WHAT IS NEURO-LINGUISTIC PROGRAMMING (NLP)?</h3>
                        <li>NLP is a set of techniques and skills that teaches you how to change your mental programs (patterns, beliefs and habits) and how to access your inner resources (coherence, creativity, self-validation, emotional- intelligence, courage, compassion, assertiveness). It is about rewiring your brain through specific words association (tone, tempo, volume, linguistic aspect).</li>
                        <div className="text-center pb-4">
                            <a href="/connect" className="btn btn-warning2">Book it</a>
                        </div>
                        <hr></hr>
                     </div>
                     <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        <div className="booking-policy bgc-dark text-center pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <p className="pb-4">Booking policy</p>
                        <p>Bookings close 2 days before the session starts.</p>
                        <p>Cancellation Policy: To cancel or reschedule, please contact us 48 hours prior to your appointment. Any cancelation or "no show up" less than 48 hours in advance cannot be accepted and service invoice will be due.</p>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        </section>
    )
}

export default Coaching;
