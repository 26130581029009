import React from 'react'

const ShopCompassion = () => {

    return (
        <section>
        <div className="mb-0 pt-5 bgc-dark">
                <div className="container shop-text">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../../img/shop/Compassion.webp')} className="img-fluid img-border" alt='Light Language Activations and Attunement'/>                            
                        </div>
                        <div className="col-md-6">
                            <h1 className="mb-3">COMPASSION ESSENCE</h1>
                            <h3 className="shop-price pt-5">$25.00</h3>
                            <p className="shop-desc pt-5">Compassion is the main function of Heart Intelligence. This Essence works on healing our heart (circulatory system, blood vessels, nervous system) and on shifting our perception from anger, sadness, jealousy, distrust to forgiveness, joy, emotional security and trust.
                            </p>
                            <a href='/connect' className='btn btn-warning2 shop-btn mt-5'>Purchase here</a>
                        </div>

                    </div>
                </div>                 
                <div className="container nav-shop pt-5">
                    <ul className="nav nav-tabs">
        		        <li className="nav-item">
        			        <a href="#description" className="nav-link active" role="tab" data-toggle="tab">Product Description</a>
        		        </li>

        		        <li className="nav-item">
        			        <a href="#ingredients" className="nav-link" role="tab" data-toggle="tab">Ingredients</a>
        		        </li>
        	        </ul>

        	<div className="tab-content pt-3">
        		<div role="tabpanel" className="tab-pane fade show active" id="description">
                    <div className="card card-body shop-card">
                        <h6>Compassion  Essence contains the following  essences alchemized: </h6>
                        <h6 className="pt-2">Ayahuasca Amazonian Essence:</h6>
                        <li>Ayahuasca (the Vine of the Soul) is a Master Plant used by indegineous shamans, curanderos and vegetalistas to clean the body of illness, of negative emotions, thoughts and energies. The Ayahuasca Essence is a Portal to visionary knowledge that expands  your awareness  and your perception up to the cellular, molecule and atom level.</li>
                        <h6 className="pt-2">Snake Essence: </h6>
                        <li>The Snake or the Serpent represents the Feminine Energy and is associated with the Kundalini Life Force. As plant Medicine, the Snake Essence works with the vibration of the master plant Ayahuasca by awakening your inner Serpent. It activates self-healing, ancestral wisdom and knowledge. </li>
                        <li>Sachamama Serpent is the Spirit Mother of the Jungle that harmonizes in oneness this world, the upper world and the underworld.  </li>           
                        <h6 className="pt-2">Condor Essence:</h6>
                        <li>The Condor represents our direct connection with the Creator, seen as the Divine Mother, Divine Father and Holy Spirit in Oneness. He is a symbol of the upper world. The Condor Essence works as a “bridge” reconnecting us with Christ Consciousness, with the purity and innocence of our own Spirit. The energetic vibration of this essence is Divine Grace. </li>
                        <h6 className="pt-2">Dolphin Essence:</h6>
                        <li>The Spirits of the dolphins and whales are assisting us in activating the Consciousness of Remembering. The Dolphin is a keeper of the Light Language Codes of Lemuria, the last Golden Age on Earth. The Dolphin Essence activates and upgrades our Heart Chakra, opening fully our Heart towards unconditional love and acceptance. The energetic vibration of this Essence is Inner Child Love.</li>

                    </div>
                </div>
        		<div role="tabpanel" className="tab-pane fade" id="ingredients">
                    <div className="card card-body shop-card">
                        <h6>Ingredients:</h6>
                        <li>Light Language Activated Water, Brandy, Aya’s Amazonian Vibration, Condor Essence, Snake Essence, Dolphin Essence.</li>
                        <h6 className="mt-2">Instructions:</h6>
                        <li>Take 6 drops, 3 times per day or when needed.</li>
                    </div>
                </div>
        	</div>
            </div>

                <div id="container">
                    <div className="row pt-5">
                        <div className="col-md-12 text-center text-white">
                            <h2>SIMILAR PRODUCTS</h2></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <a href="/shop">
                                <img src={require('../../img/shop/Ayahuasca.webp')} className="img-fluid img-border" alt='Compassion'/>
                            </a>
                            <a href="/shop">
                                <h5 className="hshop">AYAHUASCA VIBRATIONAL ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                        <div className="col-md-4">
                            <a href="/shop/courage">
                                <img src={require('../../img/shop/Courage.webp')} className="img-fluid img-border" alt='Courage'/>                            
                            </a>
                            <a href="/shop/courage">
                                <h5 className="hshop">COURAGE ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                        <div className="col-md-4">
                            <a href="/shop/creativity">
                                <img src={require('../../img/shop/Creativity.webp')} className="img-fluid img-border" alt='Creativity'/>                            
                            </a>
                            <a href="/shop/creativity">
                                <h5 className="hshop">CREATIVITY ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                    </div>
                </div>
                  
        </div>
        </section>
    )
}

export default ShopCompassion;

