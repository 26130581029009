import React from 'react'

const ComingSoon = () => {
    return (
        <section>
        <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 service"></div>
                        <h1 className="text-center centered2 bold">COMING SOON</h1>
                        <h6 className="text-center centeredsmall">Check our current services here</h6>
                        <div className="text-center centered">
                            <a href="/services" className="button btn btn-outline-light">Services<i className="arrow right"></i></a>
                        </div>
                    </div>
                </div>
            <div id="content"></div>
        </div>
        </section>
    )
}

export default ComingSoon;
