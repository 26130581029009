import React, { useState } from 'react';
import * as emailjs from 'emailjs-com';

const Connect= () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
    
      const {name,email,message} = formData;
    
      const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });


        function sendEmail(e) {
            e.preventDefault();

            checkonclick();

                emailjs.send(
                    'service_a7tebff',
                    'template_hkc5qeh',
                    formData,
                    'user_NRUirnUGBkFsGHdwc50pv'
                )

                clearFields();

          }

          function checkonclick(){
              if(formData.email==='' || formData.name==='' || formData.message===''){
                  console.log("Please fill");
            }else{
                alert("Your message was succesfully sent!");
            }
          }

          function clearFields(){
              formData.email='';
              formData.message='';
              formData.name='';
          }

    return (
        <div className="container-fluid container-connect bgc-dark pt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                <form onSubmit={sendEmail}>
                    <h1 className='text-center font-weight-bold bold-gold mb-2'>Contact us for more details</h1>
                    <h1 className='text-center font-weight-bold bold-gold mb-5'><a href='https://www.facebook.com/Light-Language-DNA-Activation-with-Anca-Botez-172822956689352/'><i className="fab fa-facebook"></i></a></h1>
                    <div className="form-group">
                        <label id="name">Full Name: <span style={{color:'red'}}>*</span></label>
                        <input type="text" className="form-control" placeholder="Please enter your full name" name="name" value={name} onChange={onChange} required/>
                    </div>
                    <div className="form-group">
                        <label for="email">Email address: <span style={{color:'red'}}>*</span></label>
                        <input type="email" className="form-control" placeholder="Please enter your email " name="email" value={email} onChange={onChange} required/>
                    </div>
                    <div className="form-group">
                        <label for="message">Message: <span style={{color:'red'}}>*</span></label>
                        <textarea type="text" className="form-control" rows="5" placeholder="Please enter your message" name="message" value={message} onChange={onChange} required/>
                    </div>
                    {/* <div class="alert alert-success" role="alert" id='alert-success'>
                        <strong>Thank you!</strong> Your message was succesfully sent!
                    </div>
                    <div class="alert alert-danger" role="alert" id='alert-danger'>
                        <strong>Please</strong> fill in all the fields!
                    </div> */}
                    <p className="text-light"> <span style={{color:'red'}}>*</span> Please fill in all the fields or find us on <a href="https://www.facebook.com/Light-Language-DNA-Activation-with-Anca-Botez-172822956689352/">FACEBOOK</a></p>
                    <div className="text-center mt-5">
                        <button type="submit" className="btn btn-warning btn-lg" onClick={checkonclick}>Submit</button>
                    </div>
                </form>
                </div>
                
            </div>
            
        </div>
    )
}

export default Connect;

