import React from 'react'

const Home = () => {
    return (
        <div>
        <div className="jumbotron jumbotron-fluid mb-0 main bgc-dark">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 id="main-text"><span id="bold">O</span>riginal</h5>
                        </div>
                        <div className="col-md-6">
                                <h5 id="secondary-text"><span id="bold">C</span>onsciousness</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron jumbotron-services jumbotron-fluid mb-0 bgc-dark">
                <div className='container-fluid text-center text-white'>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <img src={require('../../img/services/Elem.webp')} width='30px' alt="Element"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bgc-dark">
                <div className="row">
                    <div className="col-md-7 offset-md-3">
                        <p className="main-text-white">Consciousness is your unique awareness state that shows how you perceive  yourself, your surroundings and the world. Furthermore, Original Consciousness    represents the blueprint of your own emotions, thoughts, memories from the  beginning of Creation through all the incarnations, from etherical to physical. We access this state of awareness and we expand our multidimensional consciousness through Light Language Keys and Codes.</p>
                        <h2 className="events-text text-center pb-5 pt-5">WHAT IS LIGHT LANGUAGE</h2>
                        <p className="main-text-white">Light Language is a non-linear form of communication that cannot be understood by the logical, analytical mind. Therefore, cannot be translated word by word. Still, at a Soul level everybody recognizes it because Light Language is the Language of our DNA. As a multidimensional form of communication, Light Language is a channeled live stream with information from past, present and future.</p>
                        <p className="main-text-white">Light Language is like speaking in tongues.</p>
                        <p className="main-text-white">This ancient language is a form of sound and light healing and it has the power to rewire our emotions, our thoughts and to rejuvenate our cells. </p>
                        <h2 className="events-text text-center pb-5 pt-5">KEYS & DNA</h2>
                        <p className="main-text-white">The Light Language codes are specific keys that unlock inactive strands in our DNA in order for us to awaken to a higher consciousness. Skills and abilities from past lives are being reactivated and information from the future is downloaded through communication with our Multidimensional Light Language Avatars.</p>
                        <p className="main-text-white">We exist right here and right now incarnated in this physical body, but at the same time we exist in other dimensions and galaxies; we just have different forms and shapes that we call "Avatars".</p>
                        <h2 className="events-text text-center pb-5 pt-5">FORMS OF MANIFESTATION</h2>
                        <p className="main-text-white">Light Language is expressed through different forms: channeled words (linguistically), written symbols combined with numeric sequences, physical movement, sound (crystal singing bowls, shamanic drums, toning, binaural beats).</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Home;