import React from 'react'
import {Link} from 'react-scroll'
const LightLanguageSeals = () => {
    return (
        <section>
        <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 service"></div>
                        <h1 className="text-center centered ">READ  MORE  ABOUT <br></br> OUR SERVICES</h1>
                        <section id='section07' className='demo text-center centered mt-5 smooth-scroll'>
                             <Link to="content" smooth="true" duration="2000"><span></span><span></span></Link>
                        </section>
                    </div>
                </div>
                <div id="content"></div>
        </div>
        
        <div className="jumbotron jumbotron-fluid mb-0 bgc-dark services-text">
            <div className="container">
                <h1 className="text-center mb-3 pt-5">LIGHT LANGUAGE SEALS</h1>
                <h5 className="text-center mt-5 mb-2 service-price">Custom fee - Contact us for details</h5>
            </div>
        </div>
        <div className="services-image bgc-dark">
            <div className="container">
                <div className="row text-center">
                <div className="col-1"></div>
                    <div className="col-5">
                        <img src={require('../../img/services/Tablou1.webp')} className="img-fluid" alt='Light Language Activations and Attunement'/>                            
                    </div>
                    <div className="col-5">
                        <img src={require('../../img/services/Tablou2.webp')} className="img-fluid" alt='Light Language Activations and Attunement'/>                            
                    </div>
                </div>
                <div className="col-1"></div>
            </div>
        </div>
        <div className="services-text bgc-dark pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <ul>
                            <li className="nobullet">Light Language Seals are customized energetical signatures, attuned to your celular, emotional and mental vibrational field. The seal  is an alchemy between light language written signs, sacred geometry shapes, numeric codes and specific crystals. The codes work on clearing and transmuting low vibrational energies in the space where they are placed, activating on the same time the fields you want to amplify: compassion, love and acceptance, harmony and peace, abundance and manifestation, healing and evolution, cellular rejuvenation, etc.</li>
                        </ul>
                        <div className="text-center pb-4">
                            <a href="/connect" className="btn btn-warning2">Book it</a>
                        </div>
                        <hr></hr>
                     </div>
                     <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        <div className="booking-policy bgc-dark text-center pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <p className="pb-4">Booking policy</p>
                        <p>Bookings close 2 days before the session starts.</p>
                        <p>Cancellation Policy: To cancel or reschedule, please contact us 48 hours prior to your appointment. Any cancelation or "no show up" less than 48 hours in advance cannot be accepted and service invoice will be due.</p>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        </section>
        

    )
}
export default LightLanguageSeals;