import React from 'react'

const About = () => {
    return (
        <div className='jumbotron jumbotron-fluid mb-0 bgc-dark'>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-6 text-center">            
                        <img src={require('../../img/about/Anca-about.webp')} id='anca-about' alt='Anca'/>
                    </div>
                    <div className="col-md-6">
                        <blockquote className="pt-5">
                            <p className="about-upper-text">I believe in the power of <span className='bold-gold'>cultivating strong characters</span> and communities and I am here in service
                            to people, to inspire hope, conﬁdence, fulﬁlment.
                            I honor the legacy of all the remarkable people that touched my Soul during my Journey and I
                            am here to <span className='bold-gold'>give back to you</span> what was given to me.</p>
                        </blockquote> 
                        <img src={require('../../img/about/Signature.webp')} id='anca-signature' alt='Anca Botez'/>
                    </div>
                </div>
            </div>
            <div className="container container-about" style={{border: '1px solid #ca8d2f'}}>
                <div className="row">
                    <div className="col-md-12">
                        <h5 className='p-5 text-center gold-text about-txt'>JOURNEY & MISSION</h5>
                        <p>My Spiritual Journey started in the US and merged towards temples and indigenious communities in Bali, India, Peru, Hawaii, Greece, Egypt where I transitioned, studied and meditated with Priests, Shamans and Medicine People. Their teachings, their transparent way of living, thinking and feeling inspired me to change my life in a more meaningful way. They touched my Soul.</p>
                        <p>Now I facilitate Workshops and Spiritual Retreats (Peru, Sedona, Romania) where I bring together ancestral methods and rituals from these native tribes and I combine them with techniques and skills from Neuro-linguistic Programming, Family Constellations, Reiki, Psychotherapy and Vibrational Medicine.</p>
                        <h5 className='p-5 text-center gold-text about-txt pt-5'>IN SERVICE</h5>
                        <p>I believe in the power of cultivating strong characters and communities and I am here in service to people, to inspire hope, conﬁdence, fulﬁlment. I honor the legacy of all the remarkable people that touched my Soul during my Journey and I am here to give back to you what was given to me.</p>
                        <p style={{color: '#ca8d2f'}}>BIO: Anca Botez graduated from Metropolitan College of New York (MCNY) with an MBA degree in General Management and holds a Neuro-Linguistic Programming Certiﬁcation from the NLP Center of New York. A former General Manager for an American IT Company, Anca facilitates now Mindfulness and Wellbeing Programs in the Educational and Organizational Sector, serving the local and expat community in Romania, US and worldwide. She believes in the unlimited human potential, supporting children, parents and teachers to live fulﬁlling lives aligned on the path of compassion, progress and individual & collective consciousness expansion.</p>
                    </div>
                </div>
            </div>
            
            <div className="container pt-5" role="main">
                <div className="center-block text-center">
                    <a href="/connect" className="btn btn-warning-about mr-5">Connect</a>
                    <a href="/services" className="btn btn-warning-about2">Services</a>
                 </div>
            </div>
        </div>
    )
}

export default About;
