import React, {useState, useEffect} from 'react'

const Footer = () => {
    const [date , setDate] = useState();

    const getYear = () =>  setDate(new Date().getFullYear())


    useEffect(() => {
        getYear();
    }, [])
    return (
        <div>
            <footer className="footer bgc-dark text-white pt-5">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-12">
                        <img src={require('../../img/services/footerimg.webp')} className="img-fluid" alt='OC'/>                            
                    </div>
                    </div>
                </div>
            <div className="container container-nonmobile">
                <div className="row footer-row justify-content-center">
                    <div className="col col-lg-2 mt-3">
                        <ul className="list-unstyled">
                                <li><a href="/services">Services</a></li>
                                <li><a href="/events">Events</a></li>
                                <li><a href="/retreats">Retreats</a></li>
                                <li><a href="/shop">Shop</a></li>

                            </ul>
                    </div>
                    <div className="col-md-auto mt-3 text-center">
                        <ul className="list-unstyled">
                            <li>
                                <img src={require('../../img/Brand.png')} alt="OC" className="d-inline-block align-top brand"></img>
                            </li>
                            <a href="mailto:home@originalconsciousness.com">home@originalconsciousness.com</a>
                            <h1 className='text-center font-weight-bold bold-gold'><a href='https://www.facebook.com/Light-Language-DNA-Activation-with-Anca-Botez-172822956689352/'><i className="fab fa-facebook"></i></a></h1>
                        </ul>
                    </div>
                    <div className="col col-lg-2 mt-3">
                        <ul className="list-unstyled float-right">
                            <li><a href="/community">Community</a></li>
                            <li><a href="/connect">Connect</a></li>
                            <li><a href="/about">Anca Botez</a></li>
                            {/* <li><a href="/about">Legal aspects</a></li> */}
                        </ul>
                    </div>
                    {/* <div className="col-md-3 mt-3 text-center">
                        <div className="footer-head">
                            <button type="button" className="btn btn-outline-light">Connect<i className="arrow right"></i></button>
                        </div>
                    </div> */}
                </div>
                <hr className="solid"></hr>
            </div>
            <div className="container-mobile">
                <div className="row footer-row justify-content-center">
                    <div className="col col-lg-2 mt-3">
                        <ul className="list-unstyled text-center">
                                <li><a href="/services">Services</a></li>
                                <li><a href="/events">Events</a></li>
                                <li><a href="/retreats">Retreats</a></li>
                                <li><a href="/shop">Shop</a></li>

                            </ul>
                    </div>
                    <div className="col col-lg-2 mt-3">
                        <ul className="list-unstyled text-center">
                            <li><a href="/community">Community</a></li>
                            <li><a href="/connect">Connect</a></li>
                            <li><a href="/about">Anca Botez</a></li>
                            {/* <li><a href="/about">Legal aspects</a></li> */}
                        </ul>
                    </div>
                    <div className="col-md-auto mt-3 text-center">
                        <ul className="list-unstyled">
                            <li>
                                <img src={require('../../img/Brand.png')} alt="OC" className="d-inline-block align-top brand"></img>
                            </li>
                            <a href="mailto:home@originalconsciousness.com">home@originalconsciousness.com</a>
                            <h1 className='text-center font-weight-bold bold-gold'><a href='https://www.facebook.com/Light-Language-DNA-Activation-with-Anca-Botez-172822956689352/'><i className="fab fa-facebook"></i></a></h1>
                        </ul>
                    </div>
                    {/* <div className="col-md-3 mt-3 text-center">
                        <div className="footer-head">
                            <button type="button" className="btn btn-outline-light">Connect<i className="arrow right"></i></button>
                        </div>
                    </div> */}
                </div>
                <hr className="solid"></hr>
            </div>
            <div className="container footer-lowest">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p>© {date} OC. ALL RIGHTS RESERVED.</p>
                    </div>
                </div>
            </div>
            </footer>
        </div>
    )
}

export default Footer;
