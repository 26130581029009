import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Home from './components/layout/Home';
import Services from './components/layout/Services';
import LightLanguageReiki from './components/layout/LightLanguageReiki';
import DNAShamanic from './components/layout/DNAShamanic';
import LightLanguageActivation from './components/layout/LightLanguageActivation';
import LightLanguageSeals from './components/layout/LightLanguageSeals';
import SoundHealing from './components/layout/SoundHealing';
import FamilyConstelations from './components/layout/FamilyConstellations';
import PastLife from './components/layout/PastLife';
import Coaching from './components/layout/Coaching';
import About from './components/layout/About';
import Connect from './components/layout/Connect'
import Events from './components/layout/Events'
import Community from './components/layout/Community'
import Shop from './components/layout/Shop';
import ShopCompassion from './components/layout/ShopCompassion';
import ShopCourage from './components/layout/ShopCourage';
import ShopCreativity from './components/layout/ShopCreativity';
import Retreats from './components/layout/Retreats';
import ComingSoon from './components/layout/ComingSoon';
import Footer from './components/layout/Footer';
import './App.css';


const App = () => {
  return (
    <Router>
      <Fragment>
        {/* <App env={this.props.env} /> */}
        <Navbar />
        <Route exact path='/' component={Home}/>
        <Route exact path='/services' component={Services} />
        <Route path='/services/reiki' component={LightLanguageReiki} />
        <Route path='/services/dna' component={DNAShamanic} />
        <Route path='/services/activation' component={LightLanguageActivation} />
        <Route path='/services/seals' component={LightLanguageSeals} />
        <Route path='/services/sound' component={SoundHealing} />
        <Route path='/services/family-constellations' component={FamilyConstelations}/>
        <Route path='/services/coaching' component={Coaching}/>
        <Route path='/services/past-life-regression' component={PastLife}/>
        <Route path='/about' component={About}/>
        <Route path='/events' component={Events}/>
        <Route exact path='/shop' component={Shop}/>
        <Route path='/connect' component={Connect}/>
        <Route path='/community' component={Community}/>
        <Route path='/retreats' component={Retreats}/>
        <Route path='/shop/compassion' component={ShopCompassion}/>
        <Route path='/shop/creativity' component={ShopCreativity}/>
        <Route path='/shop/courage' component={ShopCourage}/>
        <Route path='/comingsoon' component={ComingSoon}/>
        <Footer />
      </Fragment>
    </Router>
  );

}



export default App;
