import React from 'react'

const Events = () => {
    return (
        <div>
            <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-white events"></div>
            <div className="container container-events bgc-dark">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="events-text text-center mb-3 pt-5">EVENTS</h2>
                        <ul>
                            <li className="events-text-white">
                                The purpose of our life is liberation and evolution, to free ourselves from our emotions, our thoughts and our body. Your personal Journey means walking the path of self-understanding, self-transformation, self-discipline, so you can reconnect to the divine within and live a more balanced and fulfilling life. It implies acknowledging your unhealthy patterns, behaviours and habits and taking action towards changing them. The gols is transitioning to compassion, to courage, to creativity, while walking the path of healing and consciousness expansion. 
                            </li>
                            <li className="events-text-white">
                                If you are committed towards improving  your life and your personal and professional  relationships, I invite you to join our Events and Workshops.
                            </li>
                        </ul>
                        <div className="text-center pb-5">
                            <a href="/connect" className="btn btn-warning shop-btn">Join Here</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-4">
                        <img src={require('../../img/events/Events-1.png')} className="img-fluid img-border" alt='Events'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/events/Events-02.webp')} className="img-fluid img-border" alt='Events'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/events/Events-03.webp')} className="img-fluid img-border" alt='Events'/>                            
                    </div>
                </div>
            </div>
            <div className="container pt-5 pb-6">
                <div className="row">
                    <div className="col-md-4">
                        <img src={require('../../img/events/Events-04.webp')} className="img-fluid img-border" alt='Events'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/events/Events-05.webp')} className="img-fluid img-border" alt='Events'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/events/Events-06.webp')} className="img-fluid img-border" alt='Events'/>                            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events;
