import React from 'react'
import {Link} from 'react-scroll'
const FamilyConstellations = () => {
    return (
        <section>
        <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 service"></div>
                        <h1 className="text-center centered ">READ  MORE  ABOUT <br></br> OUR SERVICES</h1>
                        <section id="section07" className="demo text-center centered mt-5 smooth-scroll">
                             <Link to="content" smooth="true" duration="2000"><span></span><span></span></Link>
                        </section>
                    </div>
                </div>
                <div id="content"></div>
        </div>
        
        <div className="jumbotron jumbotron-fluid mb-0 bgc-dark services-text">
            <div className="container">
                <h1 className="text-center mb-3 pt-5">FAMILY CONSTELLATIONS</h1>
                <h6 className="text-center mb-5 mt-5 service-price">
                    <span>1 hr | $88 fee</span></h6>
            </div>
        </div>
        <div className="services-image bgc-dark">
            <div className="container">
                <div className="row text-center">
                <div className="col-3"></div>
                    <div className="col-6">
                        <img src={require('../../img/services/Constellations_webp_border.webp')} className="img-fluid" alt='Family Constellations'/>                            
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
        <div className="services-text bgc-dark pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <li>Family Constellations are a blend of different modalities like hypnotherapy, psychoanalysis, Gestalt therapy, systemic family therapy. “The orders of love” represent the base of Family Constellations, founded by Bert Helinger. </li>
                        <li>Family constellations are an alternative therapeutic method with focus on the family, as the process shows the hidden dynamic in our family and relationships, the entanglement within.</li>
                        <li>Usually in a Constellation, participants (members who are not related) are asked to represent a family member, a coworker or a partner for the seeker. The seeker is the person who is looking for a resolution, an answer. As the seeker is watching the participants movement from outside, he/she will gain a new perspective on the issue  he/she it's trying to find an answer for.</li>
                        <h3 className="pt-5 pb-5 text-center">FAMILY COSTELLATIONS BENEFITS</h3>
                        <li>The Constellation Process offers us support to deal with the background of illness, addictions, failure, trauma, conflict, death, destructive behaviour within our family and much more.</li>
                        <li>Family Constellations are suitable for families and couples (maintain and improve relationships), children (school and family issues, addictions, bullying), in a personal or professional setting (failure, decision making, leadership).</li>
                        <div className="text-center pb-4">
                            <a href="/connect" className="btn btn-warning2">Book it</a>
                        </div>
                        <hr></hr>
                     </div>
                     <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        <div className="booking-policy bgc-dark text-center pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <p className="pb-4">Booking policy</p>
                        <p>Bookings close 2 days before the session starts.</p>
                        <p>Cancellation Policy: To cancel or reschedule, please contact us 48 hours prior to your appointment. Any cancelation or "no show up" less than 48 hours in advance cannot be accepted and service invoice will be due.</p>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        </section>
        

    )
}
export default FamilyConstellations;