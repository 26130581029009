import React,{useState} from 'react'

const Community = () => {
    const goldborder = {
        border: '1px solid #ca8d2f',
      }
const [readMore,setReadMore]=useState(false);
  const extraContent=<div>
      <p className="extra-content events-text-white-more">
      All these programs are merging now into ORIGINAL CONSCIOUSNESS, a self-sustainability platform focused on offering Mindfulness and Wellbeing Programs in the       Educational, Social and Organizational Sector. We are fully dedicated in supporting students, parents, teachers, business teams and new entrepreneurs. We are here for you.
      </p>
      <p className="extra-content events-text-white-more">
        If you feel the call, we kindly invite you to join our amazing Community, we are all here in service for a greater purpose.
      </p>
      </div>
  const linkName=readMore?'Read Less... ':'Read More... '
    return (
        <div>
            <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-white community"></div>
            <div className="container container-events bgc-dark">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="events-text text-center mb-3 pt-5">COMMUNITY</h2>
                        <p className="events-text-white">
                        For us “Community”  means embracing diversity and growth in every field, every shape and at all ages. The initial pilot program, the base of the Original Consciousness Platform, is  “Business Moms'' (www.mameinafaceri.ro). Initiated in 2013, the Business Consulting Program supports mothers like us that want to venture into   entrepreneurship.                    
                        </p>
                        <p className="events-text-white">Our Pilot Program flourished over time into a Social Responsibility Project called “Wings Project” with focus on the social rehabilitation of the teenagers that exited the foster care system in Brasov. In Brasov our partner is “Scut Association” while in Bucharest we partner with “Ana & Copiii” on preventing the school dropout with focus on Mindfulness Programs. Through summer camps, we partner with the       “National Police Force” teaching teanagers  mindfulness skills and techniques to 
                        improve the quality of their life and to contribute to a more meaningful community and society.<a className="read-more-link" onClick={()=>{setReadMore(!readMore)}}><span><br></br>{linkName}</span></a>
                        {readMore && extraContent}</p>
                        
                        <div className="text-center pb-4">
                            <a href="/connect" className="btn btn-warning shop-btn">Join Here</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bgc-dark mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="events-text text-center mb-3 pt-5">TESTIMONIALS</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid bgc-dark">
                <div id="carouselControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active pt-5">
                            <div className='row'>
                                <div className='col-md-8 offset-md-3'>
                                    <div className='card bgc-dark' style={goldborder}>
                                        <div className='card-horizontal'>
                                            <div className='card-body card-testimonial'>
                                            <h1 className="card-title text-center">"</h1>
                                                <p>Both individual and group sessions are truly amazing journeys in which you become aware of the light, beauty and love that are hidden in each of our souls. My individual experience was unique, gentle and profound and surely represented the ‘awakening’ I needed in that moment. Strongly recommend!</p>
                                                <div className="container text-center bold-gold">
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                </div> 
                                                <h4 className="container text-center name-testimonial pt-3">
                                                    ALINA FILOTI
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item pt-5">
                        <div className='row'>
                                <div className='col-md-8 offset-md-3'>
                                    <div className='card bgc-dark' style={goldborder}>
                                        <div className='card-horizontal'>
                                            <div className='card-body card-testimonial'>
                                                <h1 className="card-title text-center">"</h1>
                                                <p>An amazing experience,filled with the divine love & light and which you can truly feel it till deep into your bones,a true light worker in my opinion ! After the private session with her, I had few days of deep feelings of peace within,like I was floating around and a knowing inside me that we are so loved , we are not alone and all will be alright!! Definitely recommend!</p>
                                                <div className="container text-center bold-gold">
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                </div> 
                                                <h4 className="container text-center name-testimonial pt-3">
                                                    ALEXANDRA BEATRICE RUJAN
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item pt-5">
                        <div className='row'>
                                <div className='col-md-8 offset-md-3'>
                                    <div className='card bgc-dark' style={goldborder}>
                                        <div className='card-horizontal'>
                                            <div className='card-body card-testimonial'>
                                                <h1 className="card-title text-center">"</h1>
                                                <p>I felt a channel of a very clean frequency, and this made me feel comfortable with it and gave me the impetus to open very easily and naturally. It was what I needed and what my soul was looking for to show me and I was able to "hear"</p>
                                                <div className="container text-center bold-gold">
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                </div> 
                                                <h4 className="container text-center name-testimonial pt-3">
                                                MADALINA RADU
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Community;
