import React from 'react'
import {Link} from 'react-scroll'
const LightLanguageActivation = () => {
    return (
        <section>
        <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 service"></div>
                        <h1 className="text-center centered ">READ  MORE  ABOUT <br></br> OUR SERVICES</h1>
                        <section id='section07' className='demo text-center centered mt-5 smooth-scroll'>
                             <Link to="content" smooth="true" duration="2000"><span></span><span></span></Link>
                        </section>
                    </div>
                </div>
                <div id="content"></div>
        </div>
        
        <div className="jumbotron jumbotron-fluid mb-0 bgc-dark services-text">
            <div className="container">
                <h1 className="text-center mb-3 pt-5">LIGHT LANGUAGE ACTIVATIONS<br></br> AND ATTUNEMENT</h1>
                <h6 className="text-center mt-5 mb-5 service-price">
                    <span>2 hr | $222 fee</span></h6>
            </div>
        </div>
        <div className="services-image bgc-dark">
            <div className="container">
                <div className="row text-center">
                <div className="col-3"></div>
                    <div className="col-6">
                        <img src={require('../../img/services/Activationborder.webp')} className="img-fluid" alt='Light Language Activations and Attunement'/>                            
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
        <div className="services-text bgc-dark pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <li>Light Language is a non-linear form of communication that cannot be understood by the logical, analytical mind. Therefore, cannot be translated word by word. Still, at a Soul level everybody recognizes it because Light Language is the Language of our DNA. As a multidimensional form of communication, Light Language is a channeled  live stream with information from past, present and future. Light Language is like speaking in tongues.  </li>
                        <h3 className="pt-5 pb-5 text-center">IS LIGHT LANGUAGE FOR ME?</h3>
                        <li>This ancient language is a form of sound and light healing and it has the power to rewire our emotions, our thoughts and to rejuvenate our cells. The Light Language codes are specific keys that unlock inactive strands in our DNA in order for us to awaken to a higher consciousness. Skills and abilities from past lives are being reactivated and information from the future is downloaded through communication with our Multidimensional Light Language Avatars. We exist right here and right now incarnated in this physical body, but at the same time we exist in other dimensions and galaxies and we have different forms and shapes that we call “Avatars” (consciousness aspects).</li>
                        <li>Light Language is expressed through different forms: channeled words (linguistically), written symbols combined with numeric sequences and sacred geometry, physical movement, sound (crystal singing bowls, shamanic drums, toning, binaural beats).</li>
                        <li>Light Language facilitates our communication with the Source (the Creator, Angels, Archangels, Ascending Masters)  with Galactic beings from other dimensions and galaxies (Sirius, Pleiades, Arcturians, Orions, Andromeda, Lemuria, Shambala, Telos) and other multidimensional light entities.</li>
                        <li>The session can be done in person or remotely.</li>
                        <div className="text-center pb-4">
                            <a href="/connect" className="btn btn-warning2">Book it</a>
                        </div>
                        <hr></hr>
                     </div>
                     <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        <div className="booking-policy bgc-dark text-center pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <p className="pb-4">Booking policy</p>
                        <p>Bookings close 2 days before the session starts.</p>
                        <p>Cancellation Policy: To cancel or reschedule, please contact us 48 hours prior to your appointment. Any cancelation or "no show up" less than 48 hours in advance cannot be accepted and service invoice will be due.</p>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        </section>
        

    )
}
export default LightLanguageActivation;