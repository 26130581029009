import React from 'react'

const Retreats = () => {
    return (
        <div>
            <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-white retreats"></div>
            <div className="container container-events bgc-dark">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="events-text text-center mb-3 pt-5">RETREATS</h2>
                        <ul>
                            <li className="events-text-white">
                                The Spiritual Retreats we organize in Peru, Columbia, Sedona  and Romania  are  Journeys of Deep Personal Transformation. We are talking about a Journey of resistance and of letting go, the space you'll face your deepest fears and limitations in order to empower yourself. It is your consciousness rebirth, the alpha point to start creating and manifesting your life  from your highest level of consciousness. 
                            </li>
                            <li className="events-text-white">If you have been asking yourself  “WHO AM I?”, if you started to question your  feelings and your thoughts, your values and beliefs, your choices, then YES, this Soul Evolution Journey is for you. Join us, we have been waiting for you.</li>
                        </ul>
                        <div className="text-center pb-5">
                            <a href="/connect" className="btn btn-warning shop-btn">Join Here</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-4">
                        <img src={require('../../img/retreats/Retreats-01.webp')} className="img-fluid img-border" alt='Retreats'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/retreats/Retreats-02.webp')} className="img-fluid img-border" alt='Retreats'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/retreats/Retreats-03.webp')} className="img-fluid img-border" alt='Retreats'/>                            
                    </div>
                </div>
            </div>
            <div className="container pt-5 pb-6">
                <div className="row">
                    <div className="col-md-4">
                        <img src={require('../../img/retreats/Retreats-04.webp')} className="img-fluid img-border" alt='Retreats'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/retreats/Retreats-05.webp')} className="img-fluid img-border" alt='Retreats'/>                            
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../img/retreats/Retreats-06.webp')} className="img-fluid img-border" alt='Retreats'/>                            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Retreats;
