import React from 'react'

const ShopCourage = () => {

    return (
        <section>
        <div className="mb-0 pt-5 bgc-dark">
                <div className="container shop-text">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../../img/shop/Courage.webp')} className="img-fluid img-border" alt='Light Language Activations and Attunement'/>                            
                        </div>
                        <div className="col-md-6">
                            <h1 className="mb-3">COURAGE ESSENCE</h1>
                            <h3 className="shop-price pt-5">$25.00</h3>
                            <p className="shop-desc pt-5">
                            Courage is the main function of Gut Intelligence. This essence works on healing our womb  (colon, intestine, digestive system) and on shifting our    perception from fear, worrying, anxiety and depression to courage, taking action and will power.
                            </p>
                            <a href='/connect' className='btn btn-warning2 shop-btn mt-5'>Purchase here</a>
                        </div>

                    </div>
                </div>                 
                <div className="container nav-shop pt-5">
                    <ul className="nav nav-tabs">
        		        <li className="nav-item">
        			        <a href="#description" className="nav-link active" role="tab" data-toggle="tab">Product Description</a>
        		        </li>

        		        <li className="nav-item">
        			        <a href="#ingredients" className="nav-link" role="tab" data-toggle="tab">Ingredients</a>
        		        </li>
        	        </ul>

        	<div className="tab-content pt-3">
        		<div role="tabpanel" className="tab-pane fade show active" id="description">
                    <div className="card card-body shop-card">
                        <h6>Courage Essence contains the following essences alchemized:  </h6>
                        <h6 className="pt-2">Ayahuasca Amazonian Essence:</h6>
                        <li>Ayahuasca (the Vine of the Soul) is a Master Plant used by indegineous shamans, curanderos and vegetalistas to clean the body of illness, of negative emotions, thoughts and energies. The Ayahuasca Essence is a Portal to visionary knowledge that expands your awareness  and your perception up to the cellular, molecule and atom level.</li>                       
                        <h6 className="pt-2">Snake Essence: </h6>
                        <li>The Snake or the Serpent represents the Feminine Energy and is associated with the Kundalini Life Force. As plant Medicine, the Snake Essence works with the vibration of the master plant Ayahuasca by awakening your inner Serpent. It activates self-healing, ancestral wisdom and knowledge. </li>
                        <li>Sachamama Serpent is the Spirit Mother of the Jungle that harmonizes in oneness this world, the upper world and the underworld. This Essence brings into Coherence our Gut, Heart and Mind Intelligence.  </li>
                        <h6 className="pt-2">Jaguar Essence: </h6>
                        <li>The Jaguar teaches us to face our fears, it is our totem to awaken the Courage within our Gut. </li>
                        <li>As plant Medicine, the White Jaguar Spirit works with the vibration of the Master plant Bobinsana, reconnecting us with our inner strength and inner power. This Essence works mainly on empowerment and self-validation. </li>
                        <h6 className="pt-2">Mushroom Essence: </h6>
                        <li>Mushroom assists us to dig deeper within our subconscious mind, bringing awareness regarding our shadows. This Essence works on re-activating memories within our past lives, with focus on releasing and transmuting emotional trauma.</li>
                    </div>
                </div>
        		<div role="tabpanel" className="tab-pane fade" id="ingredients">
                    <div className="card card-body shop-card">
                        <h6>Ingredients:</h6>
                        <li>Light Language Activated Water, Brandy, Aya’s Amazonian Vibration, Jaguar Essence, Snake Essence, Mushroom Essence</li>
                        <h6 className="mt-2">Instructions:</h6>
                        <li>Take 6 drops, 3 times per day or when needed.</li>
                    </div>
                </div>
        	</div>
            </div>

                <div id="container">
                    <div className="row pt-5">
                        <div className="col-md-12 text-center text-white">
                            <h2>SIMILAR PRODUCTS</h2></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <a href="/shop">
                                <img src={require('../../img/shop/Ayahuasca.webp')} className="img-fluid img-border" alt='Compassion'/>
                            </a>
                            <a href="/shop">
                                <h5 className="hshop">AYAHUASCA VIBRATIONAL ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                        <div className="col-md-4">
                            <a href="/shop/compassion">
                                <img src={require('../../img/shop/Compassion.webp')} className="img-fluid img-border" alt='Courage'/>                            
                            </a>
                            <a href="/shop/compassion">
                                <h5 className="hshop">COMPASSION ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                        <div className="col-md-4">
                            <a href="/shop/creativity">
                                <img src={require('../../img/shop/Creativity.webp')} className="img-fluid img-border" alt='Creativity'/>                            
                            </a>
                            <a href="/shop/creativity">
                                <h5 className="hshop">CREATIVITY ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                    </div>
                </div>
                  
        </div>
        </section>
    )
}

export default ShopCourage;

