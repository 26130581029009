import React from 'react'
import { Link } from 'react-scroll'

const LightLanguageReiki = () => {
  return (
    <section>
      <div className='jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 service'></div>
            <h1 className='text-center centered '>
              READ MORE ABOUT <br></br> OUR SERVICES
            </h1>
            <section id='section07' className='demo text-center centered mt-5 smooth-scroll' >
              <Link to='content' smooth='true' duration={2000}>
                <span></span>
                <span></span>
              </Link>
            </section>
          </div>
          <div id='content'></div>
        </div>
      </div>

      <div className='jumbotron jumbotron-fluid mb-0 bgc-dark services-text'>
        <div className='container'>
          <h1 className='text-center mb-3 pt-5'>
            LIGHT LANGUAGE REIKI SESSIONS
          </h1>
          <h6 className='text-center mt-5 mb-5 service-price'>
            <span>1 hr | $88 fee</span></h6>
        </div>
      </div>
      <div className='services-image bgc-dark'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-3'></div>
            <div className='col-6'>
              <img
                src={require('../../img/services/Reiki-border.webp')}
                className='img-fluid'
                alt='Light Language Reiki Sessions'/>
            </div>
          </div>
          <div className='col-3'></div>
        </div>
      </div>
      <div className='services-text bgc-dark pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <li>
                Reiki is part of the Energetic Medicine and it’s an ancient,
                Japanese Healing Method.
              </li>
              <h3 className='pt-4 pb-5 text-center'>REIKI THERAPY BENEFITS</h3>
              <li>
                Reiki is based on the “palm healing” approach, as the
                Facilitator channels “Life Force Energy” and places their hands
                above different body parts (head, torso, abdomen, arms, ankles &
                others specific organs).{' '}
              </li>
              <li>
                When our body's energetic flow is blocked due to our emotional
                or mental reaction/response, a chemical imbalance is created.
                This imbalance is weakening the energetic grid of our physical
                body so we are more prone to develop illnesses that have a
                psycho-emotional cause. In this context, Reiki Energy
                reactivates the energy flow within our body (cellular & etheric
                fields) restoring our health. Reiki activates our own
                self-healing process, it boosts our immune system, it transmutes
                low vibrational energy within our cells, molecules and atoms.
              </li>
              <h3 className='pt-4 pb-5 text-center'>IS REIKI THERAPY FOR ME?</h3>
              <li>
                Reiki is a very peaceful and relaxing experience, safe and
                suitable for all ages, children including. The session can be
                done in person or remotely.{' '}
              </li>
              <li>
                Combined with Light Language, Reiki can assist us to dig deeper
                within our Subconscious Mind, working with suppressed emotions
                from childhood to maturity.{' '}
              </li>
              <div className='text-center pb-4'>
                <a href='/connect' className='btn btn-warning2'>
                  Book it
                </a>
              </div>
              <hr></hr>
            </div>
            <div className='col-md-2'></div>
          </div>
        </div>
      </div>
      <div className='booking-policy bgc-dark text-center pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <p className='pb-4'>Booking policy</p>
              <p>Bookings close 2 days before the session starts.</p>
              <p>
                Cancellation Policy: To cancel or reschedule, please contact us
                48 hours prior to your appointment. Any cancelation or "no show
                up" less than 48 hours in advance cannot be accepted and service
                invoice will be due.
              </p>
            </div>
            <div className='col-md-2'></div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default LightLanguageReiki
