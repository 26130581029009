import React from 'react'
import { Link } from 'react-scroll'

const SoundHealing = () => {
  return (
    <section>
      <div className='jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 service'></div>
            <h1 className='text-center centered '>
              READ MORE ABOUT <br></br> OUR SERVICES
            </h1>
            <section id='section07' className='demo text-center centered mt-5 smooth-scroll'>
              <Link to='content' smooth={true} duration={2000}><span></span><span></span></Link>
            </section>
          </div>
        </div>
        <div id='content'></div>
      </div>

      <div className='jumbotron jumbotron-fluid mb-0 bgc-dark services-text'>
        <div className='container'>
          <h1 className='text-center mb-3 pt-5'>SOUND HEALING</h1>
          <h6 className='text-center mb-5 mt-5 service-price'>
            <span>1 hr | $88 fee</span></h6>
        </div>
      </div>
      <div className='services-image bgc-dark'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-3'></div>
            <div className='col-6'>
              <img
                src={require('../../img/services/Soundborder.webp')}
                className='img-fluid'
                alt='Sound Healing'
              />
            </div>
          </div>
          <div className='col-3'></div>
        </div>
      </div>
      <div className='services-text bgc-dark pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <li>
                The power of Sound Therapy (quartz crystal bowls, shamanic
                drums, tibetan and peruvian bells) combined with Light Language
                promotes deep body relaxation, deep pain relief and organ
                regeneration.
              </li>
              <li>
                The tones of the Crystal Bowls create a harmonic resonance
                within our physical body because our body is water and our bones
                have a crystalline structure.{' '}
              </li>
              <li>
                These tones activate different Chakras: Notes A & B work for
                example on the Pineal Gland, activating and attuning the 3rd Eye
                Chakra and the Crown Chakra (transmuting and alchimzing low
                vibrational energies).{' '}
              </li>
              <h3 className='pt-5 pb-5 text-center'>HOW DOES IT WORK?</h3>
              <li>
                Sound Healing is known for alleviating physical pain (muscle
                relaxation) and sleep disorders, for decreasing stress and
                anxiety, for boosting the immune system. Specific Vibrations are
                applied to different body parts while a particular crystal bowl
                is placed on top of the sick organ.{' '}
              </li>
              <li>
                The 7 Crystal Bowls are synchronized to the 7 Notes (C, D, E, F,
                G, A, B) and are attuned at 432 Hz. This frequency 432 Hz is
                also known as Verdi’s A, because it has “a pure tone of math
                fundamental to nature” and is “mathematically consistent with
                the patterns of the universe, vibrating in resonance with Phi,
                the Golden Ratio.{' '}
              </li>
              <li>
                People experienced strong shifts in consciousness after Sound
                Healing with Crystal Bowls and Light Language Chanting.
              </li>
              <div className='text-center pb-4'>
                <a href='/connect' className='btn btn-warning2'>
                  Book it
                </a>
              </div>
              <hr></hr>
            </div>
            <div className='col-md-2'></div>
          </div>
        </div>
      </div>
      <div className='booking-policy bgc-dark text-center pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
              <p className='pb-4'>Booking policy</p>
              <p>Bookings close 2 days before the session starts.</p>
              <p>
                Cancellation Policy: To cancel or reschedule, please contact us
                48 hours prior to your appointment. Any cancelation or "no show
                up" less than 48 hours in advance cannot be accepted and service
                invoice will be due.
              </p>
            </div>
            <div className='col-md-2'></div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SoundHealing
