import React from 'react'

const Shop = () => {

    return (
        <section>
        <div className="pb-5 pt-5 bgc-dark">
                <div className="container shop-text">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../../img/shop/Ayahuasca.webp')} className="img-fluid img-border" alt='Light Language Activations and Attunement'/>                            
                        </div>
                        <div className="col-md-6">
                            <h1 className="mb-3">AYAHUASCA VIBRATIONAL ESSENCE</h1>
                            <h3 className="shop-price pt-5">$25.00</h3>
                            <p className="shop-desc pt-5">Aya Essence is part of Vibrational Medicine, a form of healing that channels the alive energy of this sacred Amazon Vine: Ayahuasca Banisteriopsis Caapi.
                            Vibrational Medicine also incorporates Acupuncture,
                            Homeopathy, Flowers & Crystals Essence Therapy and other holistic approaches.
                            </p>
                            <a href='/connect' className='btn btn-warning2 shop-btn mt-5'>Purchase here</a>
                        </div>

                    </div>
                </div>                 
                <div className="container nav-shop pt-5">
                    <ul className="nav nav-tabs">
        		        <li className="nav-item">
        			        <a href="#description1" className="nav-link" role="tab" data-toggle="tab">Product Description</a>
        		        </li>

        		        <li className="nav-item">
        			        <a href="#ingredients1" className="nav-link" role="tab" data-toggle="tab">Ingredients</a>
        		        </li>
        	        </ul>

        	<div className="tab-content pt-3">
        		<div role="tabpanel" className="tab-pane fade" id="description1">
                    <div className="card card-body shop-card">
                        <h6>What is Ayahuasca and its benefits?</h6>
                        <li>When you add the Ayahuasca Essence drops on your tongue, the vibration of this vine interacts directly with your energetical and physical body. Your Healing Cellular Blueprint is activated and your emotional and mental needs are released in order to be transmuted, healed and alchemized in a new energetic sequence.
                            Ayahuasca (the Vine of the Soul) is a Master Plant used by indegineous shamans, curanderos and vegetalistas to clean the body of illness, of negative emotions, thoughts and energies.
                            Ayahuasca is a Portal to visionary knowledge that expands your awareness and your perception up to the cellular, molecule and atom level.
                        </li>
                        <li>This Essence was created in the Heart of the Amazon Jungle, in Peru, working directly with the biomagnetic field of the plant and leaving the Ayahuasca Vine unharmed. The water is also imprinted with Light Language Codes. It is activated and attuned to expand your Consciousness and to awaken your Divine and Galactic Codes within your cells and DNA.
                            Storage & Dosage:
                        </li>
                        <li>The Essence is subtle, but quite strong at the same time, give it a couple of days to observe the difference. Store the Essence away from sunlight, heat and extreme temperatures.</li>
                        <h6 className="mt-2">What is Light Language?</h6>
                        <li>Light Language is the sacred language of the Heart, is a non-linear form of communication that contains the codes of creation.
                        This ancient language is a form of sound and light healing and it has the power to rewire our emotions, our thoughts and our health. The Light Language codes are specific keys to unlock inactive strands in our DNA in order for us to awaken and to switch from separation consciousness to unity consciousness.
                        </li>
                        <li>Light Language facilitates our communication with the Creator, with Angels and Archangels or Galactic beings from Sirius, Arcturus, Orion, Pleiadians and many more.</li>
                    </div>
                </div>
        		<div role="tabpanel" className="tab-pane fade" id="ingredients1">
                    <div className="card card-body shop-card">
                        <h6>Composition:</h6>
                        <li>Light Language Activated Water, Brandy, Aya’s Amazonian vibration</li>
                        <h6 className="mt-2">Instructions:</h6>
                        <li>Take 6 drops, 3 times per day or when needed.</li>
                    </div>
                </div>
        	</div>
            </div>

                 <div id="container">
                    <div className="row pt-5">
                        <div className="col-md-12 text-center text-white">
                            {/* <h2>SIMILAR PRODUCTS</h2> */}
                            </div>
                    </div>
                </div>
                {/*<div className="container container-shop-links">
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <a href="/shop/compassion">
                                <img src={require('../../img/shop/Compassion.webp')} className="img-fluid img-border" alt='Compassion'/>
                            </a>
                            <a href="/shop/compassion">
                                <h5 className="hshop">COMPASSION ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                        <div className="col-md-4">
                            <a href="/shop/courage">
                                <img src={require('../../img/shop/Courage.webp')} className="img-fluid img-border" alt='Courage'/>                            
                            </a>
                            <a href="/shop/courage">
                                <h5 className="hshop">COURAGE ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                        <div className="col-md-4">
                            <a href="/shop/creativity">
                                <img src={require('../../img/shop/Creativity.webp')} className="img-fluid img-border" alt='Creativity'/>                            
                            </a>
                            <a href="/shop/creativity">
                                <h5 className="hshop">CREATIVITY ESSENCE</h5>
                            </a>
                            <h6 className="shop-price text-center">$25.00</h6>
                        </div>
                    </div>
                </div> */}     
        </div>
            <div className="pb-5 pt-5 bgc-dark">
                    <div className="container shop-text">
                        <div className="row">
                            <div className="col-md-6">
                                <img src={require('../../img/shop/Compassion.webp')} className="img-fluid img-border" alt='Light Language Activations and Attunement'/>                            
                            </div>
                            <div className="col-md-6">
                                <h1 className="mb-3">COMPASSION ESSENCE</h1>
                                <h3 className="shop-price pt-5">$25.00</h3>
                                <p className="shop-desc pt-5">Compassion is the main function of Heart Intelligence. This Essence works on healing our heart (circulatory system, blood vessels, nervous system) and on shifting our perception from anger, sadness, jealousy, distrust to forgiveness, joy, emotional security and trust.
                                </p>
                                <a href='/connect' className='btn btn-warning2 shop-btn mt-5'>Purchase here</a>
                            </div>

                        </div>
                    </div>                 
                    <div className="container nav-shop pt-5">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a href="#description2" className="nav-link" role="tab" data-toggle="tab">Product Description</a>
                            </li>

                            <li className="nav-item">
                                <a href="#ingredients2" className="nav-link" role="tab" data-toggle="tab">Ingredients</a>
                            </li>
                        </ul>

                <div className="tab-content pt-3">
                    <div role="tabpanel" className="tab-pane fade" id="description2">
                        <div className="card card-body shop-card">
                            <h6>Compassion  Essence contains the following  essences alchemized: </h6>
                            <h6 className="pt-2">Ayahuasca Amazonian Essence:</h6>
                            <li>Ayahuasca (the Vine of the Soul) is a Master Plant used by indegineous shamans, curanderos and vegetalistas to clean the body of illness, of negative emotions, thoughts and energies. The Ayahuasca Essence is a Portal to visionary knowledge that expands  your awareness  and your perception up to the cellular, molecule and atom level.</li>
                            <h6 className="pt-2">Snake Essence: </h6>
                            <li>The Snake or the Serpent represents the Feminine Energy and is associated with the Kundalini Life Force. As plant Medicine, the Snake Essence works with the vibration of the master plant Ayahuasca by awakening your inner Serpent. It activates self-healing, ancestral wisdom and knowledge. </li>
                            <li>Sachamama Serpent is the Spirit Mother of the Jungle that harmonizes in oneness this world, the upper world and the underworld.  </li>           
                            <h6 className="pt-2">Condor Essence:</h6>
                            <li>The Condor represents our direct connection with the Creator, seen as the Divine Mother, Divine Father and Holy Spirit in Oneness. He is a symbol of the upper world. The Condor Essence works as a “bridge” reconnecting us with Christ Consciousness, with the purity and innocence of our own Spirit. The energetic vibration of this essence is Divine Grace. </li>
                            <h6 className="pt-2">Dolphin Essence:</h6>
                            <li>The Spirits of the dolphins and whales are assisting us in activating the Consciousness of Remembering. The Dolphin is a keeper of the Light Language Codes of Lemuria, the last Golden Age on Earth. The Dolphin Essence activates and upgrades our Heart Chakra, opening fully our Heart towards unconditional love and acceptance. The energetic vibration of this Essence is Inner Child Love.</li>

                        </div>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="ingredients2">
                        <div className="card card-body shop-card">
                            <h6>Ingredients:</h6>
                            <li>Light Language Activated Water, Brandy, Aya’s Amazonian Vibration, Condor Essence, Snake Essence, Dolphin Essence.</li>
                            <h6 className="mt-2">Instructions:</h6>
                            <li>Take 6 drops, 3 times per day or when needed.</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="pb-5 pt-5 bgc-dark">
                    <div className="container shop-text">
                        <div className="row">
                            <div className="col-md-6">
                                <img src={require('../../img/shop/Courage.webp')} className="img-fluid img-border" alt='Light Language Activations and Attunement'/>                            
                            </div>
                            <div className="col-md-6">
                                <h1 className="mb-3">COURAGE ESSENCE</h1>
                                <h3 className="shop-price pt-5">$25.00</h3>
                                <p className="shop-desc pt-5">
                                Courage is the main function of Gut Intelligence. This essence works on healing our womb  (colon, intestine, digestive system) and on shifting our    perception from fear, worrying, anxiety and depression to courage, taking action and will power.
                                </p>
                                <a href='/connect' className='btn btn-warning2 shop-btn mt-5'>Purchase here</a>
                            </div>

                        </div>
                    </div>                 
                    <div className="container nav-shop pt-5">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a href="#description3" className="nav-link" role="tab" data-toggle="tab">Product Description</a>
                            </li>

                            <li className="nav-item">
                                <a href="#ingredients3" className="nav-link" role="tab" data-toggle="tab">Ingredients</a>
                            </li>
                        </ul>

                <div className="tab-content pt-3">
                    <div role="tabpanel" className="tab-pane fade" id="description3">
                        <div className="card card-body shop-card">
                            <h6>Courage Essence contains the following essences alchemized:  </h6>
                            <h6 className="pt-2">Ayahuasca Amazonian Essence:</h6>
                            <li>Ayahuasca (the Vine of the Soul) is a Master Plant used by indegineous shamans, curanderos and vegetalistas to clean the body of illness, of negative emotions, thoughts and energies. The Ayahuasca Essence is a Portal to visionary knowledge that expands your awareness  and your perception up to the cellular, molecule and atom level.</li>                       
                            <h6 className="pt-2">Snake Essence: </h6>
                            <li>The Snake or the Serpent represents the Feminine Energy and is associated with the Kundalini Life Force. As plant Medicine, the Snake Essence works with the vibration of the master plant Ayahuasca by awakening your inner Serpent. It activates self-healing, ancestral wisdom and knowledge. </li>
                            <li>Sachamama Serpent is the Spirit Mother of the Jungle that harmonizes in oneness this world, the upper world and the underworld. This Essence brings into Coherence our Gut, Heart and Mind Intelligence.  </li>
                            <h6 className="pt-2">Jaguar Essence: </h6>
                            <li>The Jaguar teaches us to face our fears, it is our totem to awaken the Courage within our Gut. </li>
                            <li>As plant Medicine, the White Jaguar Spirit works with the vibration of the Master plant Bobinsana, reconnecting us with our inner strength and inner power. This Essence works mainly on empowerment and self-validation. </li>
                            <h6 className="pt-2">Mushroom Essence: </h6>
                            <li>Mushroom assists us to dig deeper within our subconscious mind, bringing awareness regarding our shadows. This Essence works on re-activating memories within our past lives, with focus on releasing and transmuting emotional trauma.</li>
                        </div>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="ingredients3">
                        <div className="card card-body shop-card">
                            <h6>Ingredients:</h6>
                            <li>Light Language Activated Water, Brandy, Aya’s Amazonian Vibration, Jaguar Essence, Snake Essence, Mushroom Essence</li>
                            <h6 className="mt-2">Instructions:</h6>
                            <li>Take 6 drops, 3 times per day or when needed.</li>
                        </div>
                    </div>
                </div>
            </div>
                  
        </div>
        <div className="pb-5 pt-5 bgc-dark">
                <div className="container shop-text">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../../img/shop/Creativity.webp')} className="img-fluid img-border" alt='Light Language Activations and Attunement'/>                            
                        </div>
                        <div className="col-md-6">
                            <h1 className="mb-3">CREATIVITY ESSENCE</h1>
                            <h3 className="shop-price pt-5">$25.00</h3>
                            <p className="shop-desc pt-5">
                            Creativity is the main function of Mind intelligence. This Essence works on bringing into coherence the right and the left brain hemispheres, harmonising the logical, analytical aspects together with the visualization, imagination and all creative processes. 
                            </p>
                            <p className="shop-desc">It activates the metaconsciousness state, with focus on being present, flow state and creativity.</p>
                            <a href='/connect' className='btn btn-warning2 shop-btn mt-5'>Purchase here</a>
                        </div>

                    </div>
                </div>                 
                <div className="container nav-shop pt-5">
                    <ul className="nav nav-tabs">
        		        <li className="nav-item">
        			        <a href="#description4" className="nav-link" role="tab" data-toggle="tab">Product Description</a>
        		        </li>

        		        <li className="nav-item">
        			        <a href="#ingredients4" className="nav-link" role="tab" data-toggle="tab">Ingredients</a>
        		        </li>
        	        </ul>

                <div className="tab-content pt-3">
                    <div role="tabpanel" className="tab-pane fade" id="description4">
                        <div className="card card-body shop-card">
                            <h6>Creativity Essence contains the following essences alchemized:  </h6>
                            <h6 className="pt-2">Ayahuasca Amazonian Essence:</h6>
                            <li>Ayahuasca (the Vine of the Soul) is a Master Plant used by indegineous shamans, curanderos and vegetalistas to clean the body of illness, of negative emotions, thoughts and energies. The Ayahuasca Essence is a Portal to visionary knowledge that expands your awareness  and your perception up to the cellular, molecule    and atom level.</li>
                            <h6 className="pt-2">Snake Essence: </h6>
                            <li>The Snake or the Serpent represents the Feminine Energy and is associated with the Kundalini Life Force. As plant Medicine, the Snake Essence works with the vibration of the master plant Ayahuasca by awakening your inner Serpent. It activates self-healing and ancestral wisdom and knowledge. </li>
                            <li>Sachamama Serpent is the Spirit Mother of the Jungle that harmonizes in oneness this world, the upper world and the underworld.  </li>
                            <h6 className="pt-2">Jaguar Essence: </h6>
                            <li>The Jaguar teaches us to face our fears, it is our totem to awaken the Courage within our Gut. </li>
                            <li>As plant Medicine, the White Jaguar Spirit works with the vibration of the Master plant Bobinsana, reconnecting you with your inner strength and inner power. This Essence works mainly on empowerment and self-validation. </li>
                            <h6 className="pt-2">Colibri Essence:  </h6>
                            <li>The Colibri/Hummingbird represents the Spirits of our Ancestors. They are the “bridge” connecting us with Gaia’s forgotten wisdom. Our Ancestors are reminding us how to heal with the sacred plants, herbs, roots, flowers, mushrooms. The energetic vibration of this Essence is Inner Alchemy Activation (Fire Sequence). It upgrades Creation, Manifestation and Implementation.</li>
                        </div>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="ingredients4">
                        <div className="card card-body shop-card">
                            <h6>Ingredients:</h6>
                            <li>Light Language Activated Water, Brandy, Aya’s Amazonian Vibration, Jaguar Essence, Snake Essence, Colibri Essence</li>
                            <h6 className="mt-2">Instructions:</h6>
                            <li>Take 6 drops, 3 times per day or when needed.</li>
                        </div>
                    </div>
                </div>
            </div>
                  
        </div>

        
        </section>
    )
}

export default Shop;

