import React from 'react'
import {Link} from 'react-scroll'
const PastLife = () => {
    return (
        <section>
        <div className="jumbotron jumbotron-fluid jumbotron-service mb-0 bgc-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 service"></div>
                        <h1 className="text-center centered ">READ  MORE  ABOUT <br></br> OUR SERVICES</h1>
                        <section id="section07" className="demo text-center centered mt-5 smooth-scroll">
                             <Link to="content" smooth="true" duration="2000"><span></span><span></span></Link>
                        </section>
                    </div>
                </div>
                <div id="content"></div>
        </div>
        
        <div className="jumbotron jumbotron-fluid mb-0 bgc-dark services-text">
            <div className="container">
                <h1 className="text-center mb-3 pt-5">PAST LIFE REGRESSION</h1>
                <h6 className="text-center mt-5 mb-5 service-price">
                    <span>2 hr | $111 fee</span></h6>
            </div>
        </div>
        <div className="services-image bgc-dark">
            <div className="container">
                <div className="row text-center">
                <div className="col-3"></div>
                    <div className="col-6">
                        <img src={require('../../img/services/PastLife_webp_border.webp')} className="img-fluid" alt='Past Life'/>                            
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
        <div className="services-text bgc-dark pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <li>Past life regression is a form of hypnotherapy where you can access and retrieve memories from previous lives/incarnations</li>
                        <h3 className="pt-5 pb-5 text-center">IS REGRESSION HYPNOTHERAPY FOR ME?</h3>
                        <li>Regressions are addressing anxiety and mood disorders, also overcoming phobias.</li>  
                        <li>This procedure takes you back in time during your infancy, gestational development period or periods before this physical life. Through hypnosis, you might be put in a position to confront the origins of your actual fears rooted in a previous life. As this is a challenging approach because it might put you in a situation to face traumatic events, an interview is required prior to booking this procedure.</li>                     
                        <div className="text-center pb-4">

                        </div>
                        <div className='text-center pb-4'>
                            <a href='/connect' className='btn btn-warning2'>
                             Book it
                             </a>
                         </div>
                        <hr></hr>

                     </div>
                     <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        <div className="booking-policy bgc-dark text-center pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <p className="pb-4">Booking policy</p>
                        <p>Bookings close 2 days before the session starts.</p>
                        <p>Cancellation Policy: To cancel or reschedule, please contact us 48 hours prior to your appointment. Any cancelation or "no show up" less than 48 hours in advance cannot be accepted and service invoice will be due.</p>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
        </section>
        

    )
}
export default PastLife;